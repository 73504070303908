import React from "react";
import contact from "../contact.svg";
import { Link } from "react-router-dom";

export default function Contact() {
    document.title=`Contact - Himal Guragain`
    return (
        <>
            <div className="container mt-3">
                <Link to="/" className="btn btn-danger">
                    <i className="fa fa-arrow-left"></i> Back
                </Link>
                <div className="contact d-flex justify-content-center align-items-center mb-5 mt-3">
                    <div className="mx-3">
                        <h3 className="heading mb-4">
                            <mark>Let's talk!</mark>
                        </h3>
                        <p className="text-muted">
                            Feel free to drop a message!
                        </p>
                        <p>
                            <img
                                src={contact}
                                alt="Image"
                                className="contactImg"
                            />
                        </p>
                    </div>
                    <div className="mx-4">
                        <form
                            className="mb-5 mt-3"
                            method="post"
                            id="contactForm"
                            name="contactForm"
                            action="https://formspree.io/f/xayzdwjy"
                        >
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                id="name"
                                placeholder="Your name"
                                required
                                size="10"
                            />
                            <input
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                placeholder="Email"
                                required
                            />
                            <textarea
                                className="form-control p-3"
                                name="message"
                                id="message"
                                cols="50"
                                placeholder="Write your message"
                                required
                                style={{ height: "15rem" }}
                            ></textarea>
                            <input
                                type="submit"
                                value="Send Message"
                                className="btn btn-danger py-2 px-4"
                            />
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
