import React from "react";
import task from "../task.svg";
import { Link } from "react-router-dom";
import data from "../projects.json"

export default function Projects() {
    document.title = `Projects - Himal Guragain `;
    return (
        <>
            <div className="container mt-3">
                <Link to="/" className="btn btn-primary">
                    <i className="fa fa-arrow-left"></i> Back
                </Link>
                <div className="d-flex flex-column justify-content-center text-center mb-4">
                    <h3 className="heading mb-4 my-3">
                        <mark>Projects</mark>
                    </h3>
                    <p className="text-muted mb-0">
                        Engaging in a project not only enables you to learn a
                        new skill, but it also helps you develop the ability to
                        create and construct things.
                    </p>
                </div>

                <div
                    className="d-flex flex-wrap text-center justify-content-center align-items-center align-content-center"
                    data-aos="zoom-in"
                >
                    {data &&
                        data.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div className="card border-hover-primary hover-scale">
                                        <div className="card-body">
                                            <div className="svg-logo">
                                                <img
                                                    src={task}
                                                    alt="Project Icon"
                                                />
                                            </div>
                                            <h4 className="project-title font-weight-bold">
                                                {item.name}
                                            </h4>
                                            <p className="text-muted mb-0">
                                                {item.description}
                                            </p>
                                            <div className="d-grid mt-4  gap-2 d-md-flex justify-content-md-center">
                                                <Link
                                                    className="btn btn-primary"
                                                    target="_blank"
                                                    to={item.url}
                                                >
                                                    View Live
                                                </Link>
                                                <Link
                                                    className="btn btn-danger"
                                                    target="_blank"
                                                    to={item.github}
                                                >
                                                    Github
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </>
    );
}
