import React from "react";
import { Link } from "react-router-dom";
import img from "../img.png"
export default function About() {
    document.title= `Himal Guragain`
    return (
        <>
            <div
                className="section about-section d-flex justify-content-center align-items-center"
                id="about"
            >
                <div className="about-container d-flex justify-content-center align-items-center mx-2 my-2">
                    <div className="about-logos mx-5">
                        <div className="about-avatar mb-3">
                            <img
                                src={img}
                                loading="lazy"
                                title="Himal Guragain"
                                alt="Himal Guragain"
                                className="img_profile"
                            />
                        </div>
                        <div className="home_social d-flex justify-content-center mb-2 ">
                            <Link
                                to="https://www.linkedin.com/in/himalgnn"
                                className="home_social-icon fa-brands fa-linkedin"
                                target="_blank"
                            ></Link>
                            <Link
                                to="https://github.com/himalgnn"
                                className="home_social-icon fa-brands fa-github"
                                target="_blank"
                            ></Link>
                            <Link
                                to="https://twitter.com/himalgnn"
                                className="home_social-icon fa-brands fa-twitter"
                                target="_blank"
                            ></Link>
                            <Link
                                to="https://www.youtube.com/channel/UCz8BXTQXBktDVTeHVU8YR3A"
                                className="home_social-icon fa-brands fa-youtube"
                                target="_blank"
                            ></Link>
                        </div>
                    </div>
                    <div className="about-details text-center text-lg-start">
                        <h3 className="dark-color my-2">Himal Guragain</h3>
                        <h6 className="theme-color lead">
                            Front-End Web Developer
                        </h6>

                        <p>
                            I'm a <mark>Tech Enthusiast</mark> with experience
                            in managing WordPress, Blogger, and Ubiquiti
                            networks. Skilled in HTML, CSS, JavaScript, and
                            ReactJS, I create dynamic and responsive web
                            applications. I'm eager to apply my passion for
                            technology and work ethic to any organization.
                        </p>

                        <Link className="btn btn-primary my-2" to="projects">
                            <i className="fa-solid fa-check"></i> View Projects
                        </Link>
                        <Link className="btn btn-danger my-2 mx-3" to="contact">
                            <i className="fa-solid fa-envelope"></i> Contact
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}
